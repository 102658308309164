import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const heading = new FontFaceObserver('p22-mackinac-pro');
	const body = new FontFaceObserver('Montserrat');

	Promise.all([heading.load(), body.load()])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch(() => $html.classList.add('fonts-failed'));
};
