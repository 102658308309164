import { withPlugins, eventBus, domEvents, device } from '@spon/plugins';
import { Collapse } from 'bootstrap';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';

function Header({ node, name, plugins: { addEvents, device } }) {
	if (!node) return;
	/*
	 *		Variables
	 */

	const breakpoint = 1024;

	// Headroom
	const options = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	};

	const headroom = new Headroom(node, options);

	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	const headerBackground = node.querySelector('.c-header__dropdown');
	const searchForm = node.querySelector('.c-header__search');

	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${windowTop * -1}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const nav = toggle({
		button: node.querySelector('.o-burger'),
		name,
		activeClass: 'is-active',
	});

	/*
	 *		Functions
	 */

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'header' || target.closest('#header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.add('is-open');

		eventBus.emit('menu:open');

		lock.capture();
		closeModal();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.remove('is-open');

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});

	eventBus.on('page:exited', nav.close);
	// Main Mobile Menu END

	function onKeyDown(event) {
		const { key } = event;
		if (key === 'Escape') {
			closeModal();
			if (nav.isOpen) {
				nav.close();
			}
		}
	}

	const navAccordionItems = [...node.querySelectorAll('.collapse')];
	const headerSearch = node.querySelector('.header-search');
	const headerInput = headerSearch.querySelector('input');

	const navAccordions = navAccordionItems.map(collapseEl => {
		return new Collapse(collapseEl, {
			parent: node,
			toggle: false,
		});
	});

	function onPrimaryClick(event) {
		if (window.innerWidth < breakpoint) return;
		event.preventDefault();

		node.classList.remove('search-open');

		// if open; close.. vise versa
		if (event.target.classList.contains('collapsed')) {
			node.classList.remove('dropdown-open');
			headerSearch.classList.remove('show');

			headerBackground.style.height = '';
		} else {
			node.classList.add('dropdown-open');
			headerSearch.classList.remove('show');

			if (window.innerWidth >= breakpoint) {
				// animate BG height
				const acc = event.target.nextElementSibling ?? null;
				if (!acc) return;

				const { top } = acc.getBoundingClientRect();
				const height = acc.scrollHeight;

				headerBackground.style.height = `${headerBackground.offsetHeight}px`;
				headerBackground.style.height = `${top + height}px`;
			}
		}
	}

	function onSearchClick(event) {
		event.preventDefault();

		if (nav.isOpen) {
			nav.close();
		}

		if (node.classList.contains('search-open')) {
			closeModal();
		} else {
			node.classList.add('dropdown-open', 'search-open');
			hideNavItems();
			headerSearch.classList.add('show');

			if (window.innerWidth >= breakpoint) {
				// animate BG height

				const { top } = searchForm.getBoundingClientRect();
				const height = searchForm.scrollHeight;

				headerBackground.style.height = `${headerBackground.offsetHeight}px`;
				headerBackground.style.height = `${top + height}px`;
			}
		}

		setTimeout(() => headerInput.focus(), 160);
	}

	function closeModal() {
		node.classList.remove('dropdown-open', 'search-open');
		headerSearch.classList.remove('show');
		headerInput.blur();
		if (window.innerWidth >= breakpoint) hideNavItems(); // keep on mobile; as we want accordion open if contains current-page
	}

	function hideNavItems() {
		navAccordions.forEach(item => {
			item.hide();
		});
	}

	/*
	 *		Init
	 */
	nav.init();
	headroom.init();

	/*
	 *		Events
	 */

	addEvents({
		'click [data-nav-primary]': onPrimaryClick,
		'click [data-nav-search]': onSearchClick,
		'click [data-nav-close]': closeModal,
	});

	addEvents(document.body, {
		click: ({ target }) => {
			if (target.id === 'header' || target.closest('#header')) return;
			closeModal();
		},
		keydown: onKeyDown,
	});

	device.at(`(min-width:${breakpoint}px)`, {
		on: () => {
			// log('desktop; do nothing');
		},
		off: () => {
			// log('mobile; activate relevant active item(s)');
			const activeNavItem = node.querySelector('.c-nav__subwrapper--show');
			const activeNavAccordion = navAccordions.filter(
				a => a._element === activeNavItem
			)[0];
			if (activeNavAccordion) {
				activeNavAccordion.show();
			}
		},
	});

	return () => {
		navAccordions.forEach(item => {
			item.dispose();
		});
	};
}

export default withPlugins(domEvents, device)(Header);
